// extracted by mini-css-extract-plugin
export const star = "UserVoiceCard-module--star--3Jgio";
export const userVoiceCard = "UserVoiceCard-module--user-voice-card--2v1A4";
export const userDetail = "UserVoiceCard-module--user-detail--3eWBM";
export const ratings = "UserVoiceCard-module--ratings--1kYBp";
export const comment = "UserVoiceCard-module--comment--HUZ7f";
export const grey = "UserVoiceCard-module--grey--2_Mbf";
export const postedAt = "UserVoiceCard-module--posted-at--127Yb";
export const imgContainer = "UserVoiceCard-module--img-container--fkcwj";
export const order1 = "UserVoiceCard-module--order1--2rQb6";
export const order2 = "UserVoiceCard-module--order2--1b2Y_";
export const order3 = "UserVoiceCard-module--order3--2ZOPv";